









































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { msalLogin } from "../../auth";
import api from "@/api";
import { getRouter } from "../../router";
import store from "../../store";
import axios from "axios";

@Component
export default class Login extends Vue {
  username = "";
  password = "";
  loginFailed = false;

  get title() {
    return store.getters.title;
  }

  async microsoftLogin() {
    //Check if is filemanagerlink -> link from notification mail
    if (this.$route.query.option == "filemanager") {
      const loco = window.location;
      window.location.replace(
        `${loco.protocol}//${loco.host}${process.env.BASE_URL}externaldocuments?share=` +
          this.$route.query.share
      );
    } else {
      localStorage.clear();
      const token = await msalLogin();
      const auth = "Bearer " + token;
      store.dispatch("updateLoginType", "ms");
      this.login(auth);
    }
  }

  basicLogin() {
    localStorage.clear();
    const auth = "Basic " + btoa(this.username + ":" + this.password);
    store.dispatch("updateLoginType", "basic");
    this.login(auth);

    if (this.$route.query.option == "filemanager") {
      const loco = window.location;
      window.location.replace(
        `${loco.protocol}//${loco.host}${process.env.BASE_URL}filemanager?share=` +
          this.$route.query.share
      );
    }
  }

  login(auth: string) {
    api.setAuth(auth);
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    api
      .login()
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        if (res.status === 200) {
          // default path
          let path = "/";

          // redirect to original path prior to login if exists
          // DISABLE ALL KIND OF CACHE
          // if (localStorage.getItem('path')) {
          //   path = JSON.parse(String(localStorage.getItem('path')))
          //   localStorage.removeItem('path')
          // }
          getRouter().replace(path);
        } else {
          this.loginFailed = true;
          localStorage.removeItem("auth");
        }
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        this.loginFailed = true;
        localStorage.removeItem("auth");
      });
  }

  noActionFormSubmit(event: Event) {
    event.preventDefault();
  }
}
